/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.category_description h1 {
  font-family: Oswald;
}

.category_description ol li {
  font-size: 1rem;
  font-family: Oswald;
  font-weight: 300;
}

.header-main .header-menu {
  position: relative;
  background-color: transparent;
  margin-bottom: 30px;
  ul li {
    &.active a { border-bottom: 5px solid #5e5e5e; }
    a {
      color: #5e5e5e;
      font-family: Oswald;
      font-size: 1.87rem;
      font-weight: 300;
      &:hover {
        border-bottom: 5px solid #5e5e5e;
      }
    }
  }
}

.custom_subcategory_page {
  .category {
    .spacer {
      h2 {
        position: relative;
        &:hover:before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0.4;
          margin-top: 2px;
          margin-bottom: 2px;
        }
        a {

        }
      }
    }
  }
}

.intro--background {
  display: none;
}

.moduletable_main_search {
  background: none;
  .search_main_search_wrapper {
    display: flex;
    justify-content: center;
    input {
      border: 1px solid #545553;
      width: 30%;
    }
  }

}

.page-header h1, .browse-view h1, .orderby-displaynumber, .virtuemart_search {
  display: none;
}

.left-sidebar {
  float: left;
  width: 20%;
  margin-right: 5%;
  margin-top: 50px;
}

.main-content-wrap.w75 {
  float: left;
  width: 75%;
}

.blog {
  .item-image {
    margin-right: 20px;
  }
}

.contacts_wrapper {
  display: flex;
  //justify-content: space-between;
  .contacts_info {
    margin-right: 40px;
  }
}

.custom_category {

  .category.col-md-6 {
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .custom_category_wrapper {
    overflow: hidden;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .category-view .row .category .spacer h2 a img {
    max-width: 100%;
    max-height: 100%;
  }

  .category .spacer {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .custom_highlight_block {
    // width: 91%;
    // height: 86%;
    // margin: 2%;
    position: absolute;
    left: 22px;
    top: 22px;
    bottom: 22px;
    right: 22px;
    z-index: 5;
    border: 7px solid transparent;
    &:hover {
      border: 7px solid #ffffff;
      background-color: rgba(141, 196, 231, 0.8);
      > .custom_category_name {
        text-decoration: none;
      }
    }
  }

  .orderby-displaynumber {
    display: none;
  }

  .custom_category_name {
    position: absolute;
    z-index: 8;
    bottom: 6%;
    left: 6%;
    color: #ffffff;
    font-family: Oswald;
    font-size: 1.56rem;
    text-decoration: underline;
    font-weight: 700;
  }

  img.custom_category_icon {
    position: absolute;
    z-index: 8;
    left: 6%;
    bottom: 25%;
  }

}

.f-column-2 {
  column-count: 2;
  column-gap: 80px;
}

.f-column-3 {
  column-count: 3;
  column-gap: 80px;
}

.moduletable_breadcumbs {
  color: #959595;
  font-family: Oswald;
  font-size: 1.06rem;
  font-weight: 200;
  ul {
    li {
      float: left;
      list-style-type: none;
      span {
        color: #959595 !important;
      }
    }
  }
}

.left-sidebar {
  border-top: 1px solid #959595;
  h3, li {
    padding-left: 20px;
  }
  h3 {
    margin: 0;
    padding: 17px 0 17px 15px;
    border-bottom: 1px solid #959595;
    color: #707070;
    font-family: Oswald;
    font-size: 1.25rem;
    font-weight: 300;
  }
  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    li {
        list-style-type: none;
      a {
        color: #959595;
        font-family: Oswald;
        font-size: 1rem;
        font-weight: 200;
        line-height: 43px;
      }
      &.current {
        // height: 41px;
        background-color: #d7d7d7;
      }
    }
  }
}

/* Subcategories */
.subcategory_title_wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .custom_subcategory_title {
    top: 50%;
    text-shadow: 0 0 3px rgba(1, 1, 1, 0.75);
    color: #ffffff;
    font-family: Oswald;
    font-size: 1.87rem;
    border-bottom: 2px solid white;
    font-weight: 800;
  }
}

.custom_subcategory_page {
  .custom_bg_hover {
    &:hover {
      background: #ff0000;
    }
  }
}

/* Products page */

.spacer.product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 7px solid transparent;
  margin: 2px;
  &:hover {
    border: 7px solid #00a0b0;
    h2 a {
      text-decoration: none;
    }
  }
}

  div[class*="browse-view"] .row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

.product-container {
  .vm-product-media-container {
    padding: 0 10% 0 10%;
  }

  .browseProductImage {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      display: block;
  }
  .vm-product-rating-container {
    display: none;
  }
  .vm-product-descr-container-1 {
    .product_s_desc {
      display: none;
    }
    h2 {
      text-align: left;
      // margin: 0 0 10px 35px;
      a {
        color: #707070;
        font-family: Oswald;
        font-size: 0.93rem;
        font-weight: 300;
        text-decoration: underline;

      }
    }
  }
  .vm3pr-0 {
    display: none;
  }
  .vm-details-button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 190px;
    width: 100%;
    height: 38px;
    margin-bottom: 10px;
    border: 2px solid #7d7d7d;
    border-radius: 0;
    background-image: none;
    background-color: transparent;
    &:hover {
      background-color: #707070;
      > a { color: white; }
    }
    a {
      border: none;
      color: #545553;
      background: none;
      font-family: Oswald;
      font-size: 1.25rem;
      font-weight: 300;
      padding: 0;
    }
  }
  .custom_product_popup_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 190px;
    width: 100%;
    height: 38px;
    background-color: #00a0b0;
    cursor: pointer;
    &:hover {
      background-color: #02828f;
    }
    a {
      pointer-events: none;
      border: none;
      color: white;
      font-family: Oswald;
      font-size: 1.25rem;
      font-weight: 300;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

/* Product */
.product-container.productdetails {
  .product-neighbours, .back-to-category, .icons, .vm-product-details-container, .customer-reviews {
    display: none;
  }

  .vm-product-media-container {
    float: left;
    width: auto;
    padding: 0;
  }

  .product-info {
    float: left;
    margin-left: 20px;
    h1 {
      margin-top: 0;
      color: #707070;
      font-family: Oswald;
      font-size: 1.18rem;
      font-weight: 300;
    }
    .product-short-description {
      color: #707070;
      font-family: Oswald;
      font-size: 0.93rem;
      font-weight: 300;
    }
  }
}


/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {


}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .left-sidebar {
    display: none;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}